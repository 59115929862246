
// @import "~bootstrap/scss/bootstrap.scss";
@import "../src/assets/font-awesome-4.7.0/css/font-awesome.min.css";
@import "../src/assets/css/animate/animate.min.css";
@import "../src/assets/css/owl-carousel/owl.carousel.min.css";
@import "../src/assets/css/owl-carousel/owl.theme.default.min.css";
@import "../src/assets/css/style.css";
@import "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i";
@import "https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;700&display=swap";

/*====================================================
                  BUTTON (Generic)
======================================================*/
html{
    scroll-behavior: smooth;
} 
.btn-general {
    background-color: transparent;
    text-align: center;
    border-width: 1px;
    border-radius: 0px;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 400;
    text-transform: uppercase;
}

.btn-white {
    border-color: #2196f3;
    color: #2196f3;
    background-color: #fff;
}

.btn-white:hover,
.btn-white:focus {
    background-color: #2196f3;
    color: #fff;
    cursor: pointer;      
}

.btn-white_box {
    border-color: #fff;
    color: #fff;
}
.btn-white_box:hover,
.btn-white_box:focus {
    /* background-color: #2196f3;
    color: #fff; */
    cursor: pointer;
    border-color: #2196f3;
    color: #2196f3;
    background-color: #fff;
}

.btn-green {        
    background-color: #2196f3;
    color: #fff;
    cursor: pointer;
    border-color: #2196f3;
}

.btn-green:hover,
.btn-green:focus {
    border-color: #2196f3;
    color: #2196f3;
    background-color: #fff;
}

.greenboxBorder{
    border:1px solid green; 
    padding:1rem;
}
 
.btn-back-to-top {
    z-index: 9999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 22px;
    padding: 3px 15px;
    border-radius: 0;
    display: none;
}